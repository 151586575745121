.crude-condensate {
  .file-input {
    border: none;
    box-shadow: none;
  }
  .input-group-addon {
    vertical-align: top;
  }

  .attachment-input {
    .k-widget {
      width: 100%;
      .k-upload-selected,
      .k-clear-selected {
        display: none;
      }
    }
    .k-upload-button input {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
      cursor: pointer;
    }

    .k-file {
      border-style: solid;
      border-width: 0 0 1px;
      padding: 0.65em 1em calc(0.65em + 2px) 1em;
      white-space: nowrap;
      outline: 0;
      &:last-child {
        border-bottom-width: 0;
      }
      .k-file-name-invalid,
      .k-file-validation-message {
        color: #d81f2a;
      }
    }
    .k-icon {
      font-size: 20px !important;
      font-weight: bold !important;
      color: #000 !important;
    }
    .upload-hint {
      font-weight: 500;
      font-style: italic;
      font-size: 14px;
    }
  }
}
