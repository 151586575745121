.maa-details-page {
  .maaClass {
    overflow-y: auto;
    height: calc(100vh - 143px) !important;
  }

  .maa-form {
    padding-right: 0;
  }

  .ps2-tab-content {
    min-height: 310px;
  }
}
