html, body {
    height: 100%;
}
body{
    &.new-background{
        background-color: white;
        .cards-pf{
            background: transparent;
            height: 100%;
        }

        &.landing-page{
            background-color: #00A19C;
            &.landing-page-2{
                background-color: white;
                background-image: url(../assets/images/background3.png);
                background-position: center bottom;
                background-attachment: fixed;
                background-repeat: no-repeat;
            }
            &.landing-page-3{
              background-color: #00A19C;
              background-image: url(../assets/images/background-3.png);
              background-position: center bottom;
              background-attachment: fixed;
              background-repeat: no-repeat;
          }
            .navbar-pf-vertical{
                background-color: #3F3F3F !important;
                border-bottom: 1px #3F3F3F solid !important;
                .dropdown-active{
                    background-color: #3F3F3F !important;
                }
                .dropdown:hover .dropbtn, .dropbtn:hover{
                    background-color: #545454 !important;
                }
            }
        }

    }
    &.old-background{
        background-color: #f5f5f5;
        height: 100%;
    }
}

@media all and (max-width: 1289px){
    .navbar-collapse{
        padding-right: 7px;
    }
    .dropbtn{
        padding: 16px 5px;
        font-size: 13px;
        &:focus{
            color: white;
        }
    }
    .navbar-pf-vertical .nav .nav-item-iconic{
        padding: 16px 5px 22px 5px;
    }
    .navbar-pf-vertical .nav li.dropdown > a{
        font-size: 13px;
    }
}
.pams-template{
    .remove-padding-right{
        padding-right: 0;
    }
    .pams-navigation{
        .navbar-brand{
            position: relative;
            min-width: 180px;
            label{
                position: absolute;
                font-size: 20px;
                font-weight: 700;
                top: 15px;
            }
        }
        .pams-top-nav{
            .dropdown-content{
                background-color: #fff;
                width: 300px;
                a{
                    padding: 7px 15px;
                    &:hover{
                        background-color:#ddd;
                    }
                }
            }
            .coming-soon{
                font-size: 10px;
            }
        }
        .nav-pf-secondary-nav{
            background-color: #fff;
            border-color: #ccc;
            width: max-content;
            a{
                background-color: transparent !important;
                color: black;
                padding: 7px 15px;
                height: auto;
                &:hover{
                    .list-group-item{
                      background: #545454 !important;
                    }
                    .list-group-item-value{
                        text-decoration: none;
                    }
                }
            }
        }
        .nav-pf-secondary-nav{
          a{
            &:hover
            {
              background: #545454 !important;
            }
          }
        }
        .pams-nav-group,
        .list-group-item {   
            > a .list-group-item-value {
                word-wrap: break-word;
                white-space:normal;
                display: block;
                width: max-content;
                padding-left: 24px;
                padding-right: 0;
            }
            > a.pams-nav-group {
                color: #00A19C;
                font-size: 15px;
                padding-left: 15px;
            }
            > .list-group {
                padding-left: 15px;
            }
            > a .fa {
                width: 28px;
                margin: 0 10px;
              }
        }

        &.nav-pf-vertical {
            width: max-content;
            .nav-pf-secondary-nav {
                .list-group{
                    .list-group-item:hover > a{
                        background: #ddd !important;
                    }
                }
            }

            .list-group{
                .list-group-item:hover > a{
                    background: #545454 !important;
                }
            }
            &.collapsed .list-group-item.secondary-nav-item-pf:hover>a{
                width: 76px;
            }
            &.collapsed .list-group-item > a .hide-item {
                display: none;
            }
        }
    }
    .view{
        margin-top: 10px;
    }
    .page-content{
        padding-top: 55px !important;
        height: 100%;
    }
    .action{
        padding-left: 7px;
        .btn{
            padding-left: 7px;
            padding-right: 7px;
        }
    }
    a.btn-addnew{
        margin-top: 5px;
        color: black;
        background-color: #FFC000;
        height: 35px;
        line-height: 35px;
        font-size: 14px;
    }
    .search-box{
        padding-right: 0;
        position: relative;
    }
    .dropbtn{
      text-align: left;
        &:focus{
            color: white;
        }
        .caret {
          margin-left: 7px;
          vertical-align: inherit;
          border-top: 10px solid;
          border-block-width: 10px;
          border-right: 7px solid transparent;
          border-left: 7px solid transparent;

      }
    }
    .search-form{
        float: left;
        position: absolute;
        right: 175px;
        width:420px;
        margin-top: 5px;
        .input-group-btn{
            z-index: 100;
            position: absolute;
            right: 27px;
            top: 5px;
        }
        .input-group-addon{
            cursor:pointer;
            background: #FFC000;
            width: 25px;
            min-width: 25px;
            text-align: center;
            border-radius: 50%;
            height: 25px;
            padding: 6px;
        }
        .form-control{
            box-shadow: none;
        }
    }
    a.btn{
        background-color: rgba(0, 161, 156, 1);
        border: 1px solid #A9BFBE;
        margin-top: 5px;
        color: white;
        &:hover{
            background-color: #23ADA9;
        }
    }
    .export-excel{
      float: right;
    }
    .add-new{
      float: right;
    }
}
