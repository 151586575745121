.ps2-tab-content{
   .other-status{
       margin-top: 5px;
       padding-left: 0;
       padding-right: 0;
   }
   .other-text{
       padding-left: 0;
       padding-right: 0;
   }
}
