.ugsa-search-page {
  padding-bottom: 48px;
  overflow-x: hidden;
  width: 100%;
  .personalised-column {
    padding-left: 40%;
    align-items: center;
  }
  .ugsa-table {
    border: none;

    table {
      position: relative;

      thead {
        tr {
          th {
            white-space: initial;
            text-align: center;
            background-image: none;
            background-color: #00a19c;
            width: 116px !important;
            border-width: 1px;
          }

          th,
          .k-link,
          .k-i-filter {
            color: #fff !important;
            font-weight: 500;
          }

          .k-i-filter:before {
            content: "\e006";
          }
        }
      }

      tbody {
        max-height: 100em !important;

        tr {
          td {
            vertical-align: top;
            background-image: none;
            background-color: #efefef;
            border-width: 1px;
            border-color: #fff;
            width: 116px !important;
          }
        }
      }
    }

    .k-grid-header {
      .k-header {
        vertical-align: text-top;
        width: 116px !important;
        .k-icon {
          font-size: 24px;
          color: #fff !important;
        }
      }
    }
  }

  .table-responsive {
    position: relative;
    padding-bottom: 48px;
    overflow-x: hidden;
  }

  .text-action {
    color: #000;
  }

  .k-i-sort-asc-sm,
  .k-i-sort-dsc-sm {
    &:before {
      bottom: 8px;
    }
  }

  .k-grid-toolbar {
    background-image: none;
    background-color: #fff;
    padding: 0.22em 0 0.28em;

    .k-button {
      margin: 0.16em 0;
    }
  }

  .k-dropdown-wrap {
    display: block !important;
  }

  .grid table .k-grid-header th {
    border: 1px solid #fff;
    border-width: 0 1px !important;
    border-color: #fff !important;
  }

  .k-state-active {
    background-color: transparent !important;
  }

  .k-pager-sizes::after {
    content: "items per page";
  }

  .k-pager-wrap {
    position: relative;

    .k-pager-info {
      position: absolute;
      right: 0;
    }
  }

  .top-container {
    margin-bottom: 15px;

    .date-info {
      height: 40px;
      display: flex;

      > span {
        padding-left: 5px;
        align-self: flex-end;
      }
    }

    .top-right-container {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 12px;
      }

      .search-container {
        display: flex;
        align-items: center;
        margin-right: 12px;

        .search-button {
          cursor: pointer;
          border-radius: 50%;
          background-color: #ffc000;
          padding: 8px;
          width: 37px;

          i {
            margin-left: 3px;
          }
        }

        input {
          border: none;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .k-grid-content {
    height: 100vh !important;
    overflow-x: scroll !important;
  }

  .hidden-str {
    visibility: hidden;
  }

  .k-grid td {
    text-overflow: initial;
  }
  
  .k-grid-header {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 0 !important;
  }
  
  .k-grid-header::-webkit-scrollbar {
    background-color: #eae8e8;
  }
  #ugsa-table {
    .k-grid-pager,
    .k-grid-content,
    .k-virtual-scrollable-wrap table {
      max-width: calc(100vw - 116px);
    }
    .k-grid-header {
      max-width: calc(100vw - 116px);
    }
  }
  
  .noActionCol {
    width: 100vw;
    .k-grid-pager,
    .k-grid-content,
    .k-virtual-scrollable-wrap table {
      max-width: calc(100vw - 43px) !important;
    }
    .k-grid-header {
      max-width: calc(100vw - 43px) !important;
    }
  }
  
  .noAdminCol {
    width: 100vw;
    .k-grid-pager,
    .k-grid-content,
    .k-virtual-scrollable-wrap table {
      max-width: calc(100vw - 43px) !important  }
    .k-grid-header {
      max-width: calc(100vw - 43px) !important;
    }
  }
}
.UGSAExpiryYear {
  input {
    background-color: #fff;
    color: #2e2e2e;
    height: 1.65em;
    line-height: 1.65em;
    padding: 0.177em 0;
    text-indent: 0.33em;
    margin: 4px 0;
    border: solid 1px #c5c5c5;
    border-radius: 3px;
  }

  label {
    min-width: 40px !important;
    color: #2e2e2e;
  }

  .button-container {
    display: flex;
  }

  .invalid {
    border: solid 1px red;
  }
}
