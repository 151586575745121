.gsa-details-page {
  .gsaClass {
    overflow-y: auto;
    height: 750px !important;
  }
  #gsaDocuments {
    .ps2-tab-content {
      min-height: 800px;
    }
  }
}
