.equities {

  .display-dropdown {
    span, .k-dropdown-wrap, .k-state-default {
      display: block !important;
      background-color: lightyellow !important;
    }

    .k-icon {
      &.k-i-arrow-60-down {
        margin: 10px;
        font-size: 12px;
      }
    }
  }

  .k-input {
    background-color: lightyellow;
  }

  .k-grid td {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
  }
}
