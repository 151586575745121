.spa-summary{
    padding-left: 5px;
    .remove-padding-left{padding-left: 0;}
    .remove-padding-right{padding-right: 0;}
    .row-eq-height {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    .col-equal {
        margin: auto;
    }
    .left-box{
        img{
            margin-top: 10%;
        }
        .chart-text{
            font-size: 16px;
        }
    }
    .chart-info{
        background-color: #F5F5F5;
        display: inline-block;
        border-radius: 15px;
        padding-bottom: 15px;
        padding-right: 15px;
        .text-header{
            text-align: center;
            font-weight: 700;
            font-size: 20px;
            padding: 15px;
        }
    }
    .small-chart,.large-chart{
        position: relative;
        margin: auto;
    }
    .small-chart{
        height:120px;
        width:120px;
    }
    .large-chart{
        height:160px;
        width:160px;
    }
    .sub-chart{
        background-color: #D9D9D9;
        margin-bottom: 7px !important;
        margin-top: 7px !important;
        padding-top: 15px;
        padding-bottom: 15px;
        &.first-child{
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
        }
        &.last-child{
            border-top-right-radius: 16px;
            border-bottom-right-radius: 16px;
        }
    }
    .chart-doughnut{
        height: 100%;
        width: 100%;
    }

    .right-box{
        padding-left: 8px;
        padding-right: 8px;
        .history{
            background-color: #F5F5F5;
            margin-right: 0;
            margin-left: 0;
            padding-left: 7px;
            padding-right: 7px;
            padding-bottom: 15px;
            border-radius: 15px;
            .loading-message{
                top: 75%;
                left: 60%;
            }
            .year-input{
                margin-bottom: 5px;
                span{
                    margin-top: 5px;
                    display: block;
                }
                input{
                    border-radius: 0;
                    padding: 5px;
                    height: 25px;
                }
            }
            .group-header{
                background-color: #7f7f7f;
                color: white;
                font-size: 20px;
                text-align: center;
                border-radius: 8px;
                padding: 10px 15px;
                margin-top: -25px;
            }
            .box-container{
                padding-left: 0;
                padding-right: 0;
                background-color: white;
                display: inline-block;
                .first-group{
                    min-height: 200px;
                }
                .second-group{
                  min-height: 200px;
                }
                .col{
                    font-size: 12px;
                    i{
                        margin-left: 5px;
                    }
                    span{
                        margin-right: 5px;
                    }
                }
                .row-eq-height{
                    background-color: #A6A6A6;
                    margin-left: 0;
                    margin-right: 0;
                }
                .history-content{
                    margin-bottom: 10px;
                    margin-top: 10px;
                    margin-right: 0;
                    max-height: 200px;
                    overflow: auto
                }
                .col-header{
                    font-size: 14px;
                    &:first-child{
                        border-right: 1px solid #fff;
                    }
                    color: white;
                    padding-top: 7px;
                    padding-bottom: 7px;
                }
            }
        }
    }

    .group-template{
        .chart-box{
            height: 100%;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
        .chart-title{
            font-size: 35px;
            text-align: center;
            text-transform: uppercase;
            font-weight: 700;
        }
        .chart-title-small{
            font-size: 16px;
            &.chart-total{
                font-size: 60px;
            }
        }
        .chart-total{
            font-size: 80px;
            text-align: center;
            font-weight: 700;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
        &.panel{
            background-color: transparent;
            box-shadow: none;
            border: none;
            .panel-heading{
                background-color: #7f7f7f;
                font-size: 20px;
                text-align: center;
                border-radius: 8px;
            }
            .panel-body{
                font-size: 70px;
                text-align: center;
            }
            &.small-font{
                .panel-heading{
                    font-size: 16px;
                }
                .panel-body{
                    font-size: 45px;
                }
            }
        }
    }
    .legend{
        label{display: block;}
        .legend-hint{
            font-size: 10px;
            display: inline-block;
            &::before{
                content: ' ';
                display: inline-block;
                width: 10px;
                height: 10px;
                margin-right: 5px;
            }
            &.region,&.pa-expiry{display: block; font-size: 12px;}
            &.region1{&::before{background-color: #00A19C;}}
            &.region2{&::before{background-color: #FFC000;}}
            &.region3{&::before{background-color: #7F7F7F;}}
            &.region4{&::before{background-color: #A9D18E;}}

            &.pa-expiry1{&::before{background-color: #FF9B9B;}}
            &.pa-expiry2{&::before{background-color: #FFE699;}}
            &.pa-expiry3{&::before{background-color: #A9D18E;}}

        }
    }
    .btn-download{
        margin-top: 5px;
        background-color: #BFBFBF;
        float: right;
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
    }
    .legend-line-break{
      line-break: normal;
      padding-top: 15px;
    }
    .line-break{
      line-break: normal;
      padding-top: 50px;
    }
    .change-history-box{
      margin: 15px 0 15px 0;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }
}
