.gsa-comparison {
  .gsa-search {
    height: 30px;
  }

  .container-label {
    position: relative;
    border-style: solid;
    border-color: lightgrey;
    padding-bottom: 15px;
    padding-top: 15px;
  }

  .container-data-top {
    padding-top: 20px;
  }

  .container-data {
    padding-top: 10px;
  }

  .container-data-scroll {
    font-size: small;
    height: calc(100vh - 34rem);
    width: 100%;
    border-style: groove;
  }

  .right-content {
    margin: 0px 0px 0px 30px;
    margin-right: 10px;
    position: relative;
  }

  .text-area {
    padding-top: 15px;
  }

  .text-size {
    font-size: small;
    height: calc(100vh - 31rem);
    width: 100%;
    border-style: groove;
    overflow-y: scroll;
  }

  .container-area {
    position: relative;
    height: calc(95vh - 10rem);
    border-style: solid;
    border-color: #00A19C;
    margin-bottom: 15px;
  }

  .text-name-label {
    padding-top: 20px;
    padding-bottom: 10px;
    color: #00A19C;
    font-weight: bold;
    position: relative;
    float: left;
  }


  .pdf-button {
    position: relative;
    padding-top: 20px;
  }

  .pdf-button-info {
    height: 35px;
    line-height: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 20px;
  }

  .article-name {
    font-size: 15px;
    font-weight: bold;
    color: #00A19C;
    padding-left: 0px;
  }


  .highlightedText {
    background-color: yellow;
    color: black;
  }
  .isCurrentHighlightedText {
    background-color: orange;
  }
  
  .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
  }

  .up {
    transform: rotate(-135deg);
  }

  .down {
    transform: rotate(45deg);
  }

  .occurrence-count {
    padding-top: 4px;
    font-size: small;
    width: 26.5%;
  }

  .width-settings {
    width: 32.5%;
  }

  .article-info {
    padding-bottom: 15px;
  }

  .article-link{
    padding-left: 7%;
  }
}
