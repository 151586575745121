.spaPartiesBuyers {
  .display-dropdown {
    span, .k-dropdown-wrap, .k-state-default {
      display: block !important;
      background-color: lightyellow !important;
    }

  .k-icon {
      &.k-i-arrow-60-down {
        margin: 10px;
        font-size: 12px;
      }
    }
  }

  .k-input {
    background-color: lightyellow;
  }
  
  .grid table .k-grid-header th {
    text-align: center;
  }
  tr {
    td:last-child {
      text-align: center;
    }
  }
  .k-loading-mask{
    display: none !important;
   }
  
}
