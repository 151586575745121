ul
{
    &.breadcrumbs{
        padding-left: 5px;
        margin-top: 10px;
        list-style-type: none;
        li{
            a{
                color: #00A19C;
                &:hover{
                    text-decoration: none;
                    color: #00A19C;
                }
                
            }
            display: inline-block;
            span{
                color: #00A19C;
                &.ng-binding{
                    white-space: initial !important;
                }
            }
        }
    }
}