.spa-summary{
   .other-status{
       margin-top: 5px;
       padding-left: 0;
       padding-right: 0;
   }
   .other-text{
       padding-left: 0;
       padding-right: 0;
   }
   .remove-padding-left{
       padding-left: 0;
   }
   .remove-padding-right{
       padding-right: 0;
   }
   .other-status{
       margin-top: 5px;
   }
   .ms-options {
    min-height: 200px !important;
    max-height: 100px !important;
  }
}
