.gas-network {
  .input-group-addon {
    padding-top: 10px;
    vertical-align: top;
  }

  .display-dropdown {
    span.k-dropdown-wrap.k-state-default {
      display: block !important;

      .k-select {
        line-height: 1.7em;
      }

      .k-icon {
        font-size: 15px;
      }
    }
  }

  .gas-network-table {
    .k-i-filter {
      font-size: 15px;
    }

    .k-i-filter:before {
      content: "\e006";
    }
  }

  .k-input {
    background-color: lightyellow;
  }
}
