﻿.crude-condensate {
  .file-input {
    border: none;
    box-shadow: none;
  }
  .search-box-date {
    margin-right: 30px;
    margin-top: 10px;
    padding: 0 0 10px 5px;
  }
  span.k-dropdown-wrap.k-state-default {
    display: block !important;

    .k-select {
      line-height: 1.7em;
    }

    .k-icon {
      font-size: 15px;
    }
  }
}
