.spa-detail-form {
    padding-bottom: 15px;

    .ps2-tab{
        overflow-y: auto;
        height: calc(100vh - 143px) !important;
    }
    .ps2-form{
        padding-right: 0;        
    }
    .ps2-tab-content{
        min-height: auto;
    }
}