.pams-dashboard{
    background-color: #00A19C;
    height: 100%;
    .boxes{
        margin-top: 15px;
        padding-right: 0;
    }
    .box-template{
        height: 280px;
        background-color: #A9E9E8;
        a.btn{
            width: 100%;
            background-color: #A9BFBE;
            border: 1px solid #A9BFBE;
            margin-top: 5px;
            color: black;
            &:hover{
                background-color: #23ADA9;
            }
        }
    }
    .box-template-2{
        border-top: none;
        border-left: none;
        border-right: none;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+50&0+0,1+50 */
        background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, #d3d1d6 0px 10px 10px -5px;
        &.panel-default{
            margin-bottom: 5px;
             >.panel-heading{
                background-color: transparent;
                padding: 0;
                img{
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    object-fit:cover;
                    // height: 150px;
                    padding-bottom: -7px;
                }
             }
             >.panel-body{
                height: 180px;
                margin-top: -15px;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                backdrop-filter: blur(9px);
                h4{
                    padding-left: 0;
                    padding-top: 15px;
                }
                .btn{
                    width: 100%;
                    background-color: rgba(0, 161, 156, 1);
                    border: 1px solid #A9BFBE;
                    margin-top: 5px;
                    color: black;
                }
             }
        }
    }
    
    .db-description{
        position: relative;
        margin-top: 3%;
        color: white;
        h1{
            font-size: 45px;
        }
    }
    .pams-map{
        position: relative;
        margin-top: 15%;
    }
}
