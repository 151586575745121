.pa-form{
    .form-header{
        margin-top: 10px;
    }
    .ps2-psc-create{
        padding-bottom: 15px;
        .ps2-tab{
            overflow-y: auto;
            height: 500px;
        }
        .ps2-form{
            padding-right: 0;        
        }
        .ps2-tab-content{
            min-height: 300px;
        }
    }
}
