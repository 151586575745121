.search-comparison {
  .search-grid {
    thead {
      th {
        background-color: #00a19c;

        .k-link:link,
        .k-link:visited,
        .k-nav-current.k-state-hover .k-link {
          color: white;
          text-align: center;
        }
      }
    }
  }
  .input {
    width: 100%;
  }
  .input-search {
    position: absolute;
    right: 1px;
    top: 5px;
    z-index: 2;
    max-width: 10px;
  }

  .margin {
    margin-bottom: 12px;
    padding-right: 24px;
    div {
      min-width: 100%;
    }
  }

  .margin-field {
    margin-bottom: 12px;
    padding-right: 24px;
  }

  .search-comparison {
    > div:first-child {
      margin-top: 45px;
    }
  }

  .search-form {
    position: relative;
    right: auto;
    span {
      position: absolute;
      right: 8px;
      min-width: none !important;
    }
  }
  #compare-button {
    margin-bottom: 10px;
  }
  #compare-checkbox {
    margin-bottom: 10px;
  }

  .text-marker {
    color: #ffff00;
  }

  .seach-operator {
    margin-right: -15px;
  }
  .search-expirydate {
    margin-right: -30px;
  }
  #btn-compare {
    background-color: #00a19c;
  }
  .search-box-date {
    margin-right: 30px;
    margin-top: 10px;
    padding-bottom: 10px;
  }
  select {
    padding: 5px 20px 5px 5px;
    option {
      padding-left: 0 !important;
    }
  }
  .ms-options-wrap > button {
    color: #555 !important;
  }
  .status1 {
    padding-left: 0 !important;
  }
  .status2 {
    margin-top: 8px;
  }
  .coming-soon {
    font-style: italic;
    font-weight: 600;
    color: #555;
  }
}
