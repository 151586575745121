/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../../bower_components/fontawesome/scss/font-awesome.scss";
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "main/directives/breadcrumbs.scss";
@import "main/modules/navigation/pams-navigotion.scss";
@import "main/modules/admin/manage-crude-condensate/crude-condensate.page.scss";
@import "main/modules/admin/manage-crude-type/crude-type.scss";
@import "main/modules/admin/manage-gas-network/manage-gas-network.scss";
@import "main/modules/gsa/gsa-details/gsa-details.page.scss";
@import "main/modules/maa/maa-details/maa-details.page.scss";
@import "main/modules/maa/summary/summary.page.scss";
@import "main/modules/pams-template/article-comparison/article-comparison.page.scss";
@import "main/modules/pams-template/crude-condensate-network/crude-condensate-network.scss";
@import "main/modules/pams-template/gsa-comparison/gsa-comparison.page.scss";
@import "main/modules/pams-template/gsa-network/gas-network.scss";
@import "main/modules/pams-template/gsa-search/gsa-search.page.scss";
@import "main/modules/pams-template/gsa-search-comparison/gsasearch-comparison.scss";
@import "main/modules/pams-template/gsa-summary/gsa-summary.page.scss";
@import "main/modules/pams-template/landing-page/landing.page.scss";
@import "main/modules/pams-template/landing-page-2/landing.page.scss";
@import "main/modules/pams-template/landing-page-3/landing.page.scss";
@import "main/modules/pams-template/maa-comparison/maa-comparison.page.scss";
@import "main/modules/pams-template/maa-search/maa-search.page.scss";
@import "main/modules/pams-template/maa-search-comparison/maa-search-comparison.scss";
@import "main/modules/pams-template/maa-summary/maa-summary.page.scss";
@import "main/modules/pams-template/pa-comparison/pa-comparison.page.scss";
@import "main/modules/pams-template/pa-search/pa-search.page.scss";
@import "main/modules/pams-template/pa-summary/pa-summary.page.scss";
@import "main/modules/pams-template/poc-searching/POCsearch.scss";
@import "main/modules/pams-template/search-comparison/search-comparison.scss";
@import "main/modules/pams-template/spa-search/spa-search.page.scss";
@import "main/modules/pams-template/spa-search-comparison/spa-search-comparison.scss";
@import "main/modules/pams-template/spa-summary/spa-summary.page.scss";
@import "main/modules/pams-template/ugsa-comparison/ugsa-comparison.page.scss";
@import "main/modules/pams-template/ugsa-search/ugsa-search.page.scss";
@import "main/modules/pams-template/ugsa-search-comparison/ugsasearch-comparison.scss";
@import "main/modules/pams-template/ugsa-summary/ugsa-summary.page.scss";
@import "main/modules/psc/create-new/psc-create-new.page.scss";
@import "main/modules/spa/detail-form/detail-form.scss";
@import "main/modules/spa/landing-page/landing-page.scss";
@import "main/modules/spa/spa-list/spa-list.scss";
@import "main/modules/spa/summary/summary.page.scss";
@import "main/modules/ugsa/ugsa-details/ugsa-details.page.scss";
@import "main/modules/gsa/gsaDirective/Articles/Articles.scss";
@import "main/modules/gsa/gsaDirective/Documents/Documents.scss";
@import "main/modules/gsa/gsaDirective/Summary/Summary.scss";
@import "main/modules/maa/maaDirective/Articles/Articles.scss";
@import "main/modules/maa/maaDirective/Documents/Documents.scss";
@import "main/modules/maa/maaDirective/PartiesSellers/PartiesEquiriesSASS.scss";
@import "main/modules/maa/maaDirective/Summary/Summary.scss";
@import "main/modules/pams-template/gsa-comparison/gsa-comparison-article/gsa-comparison.scss";
@import "main/modules/pams-template/maa-comparison/maa-comparison-article/maa-comparison-article.scss";
@import "main/modules/pams-template/pa-comparison/pa-comparison-article/pa-comparison-article.scss";
@import "main/modules/pams-template/spa-comparison/spa-comparison-article/spa-comparison-article.scss";
@import "main/modules/pams-template/ugsa-comparison/ugsa-comparison-article/ugsa-comparison.scss";
@import "main/modules/psc/pscDirective/Articles/Articles.scss";
@import "main/modules/psc/pscDirective/Documents/Documents.scss";
@import "main/modules/psc/pscDirective/PartiesEquiries/PartiesEquiriesSASS.scss";
@import "main/modules/psc/pscDirective/Summary/Summary.scss";
@import "main/modules/spa/directive/Articles/Articles.scss";
@import "main/modules/spa/directive/Documents/Documents.scss";
@import "main/modules/spa/directive/PartiesBuyers/PartiesBuyers.scss";
@import "main/modules/spa/directive/PartiesSellers/PartiesSellers.scss";
@import "main/modules/spa/directive/Summary/Summary.scss";
// endinjector